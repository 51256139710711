
    import './styles.scoped.css';
    export default {
  "trigger-button-styles": "awsui_trigger-button-styles_lpshu_1jpng_141",
  "trigger-badge-wrapper": "awsui_trigger-badge-wrapper_lpshu_1jpng_164",
  "trigger": "awsui_trigger_lpshu_1jpng_141",
  "selected": "awsui_selected_lpshu_1jpng_208",
  "badge": "awsui_badge_lpshu_1jpng_228",
  "trigger-wrapper": "awsui_trigger-wrapper_lpshu_1jpng_232",
  "trigger-wrapper-tooltip-visible": "awsui_trigger-wrapper-tooltip-visible_lpshu_1jpng_240",
  "dot": "awsui_dot_lpshu_1jpng_244",
  "trigger-tooltip": "awsui_trigger-tooltip_lpshu_1jpng_257"
};
  